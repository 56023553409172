import * as React from "react"
import {
  Box,
  Button,
  Center,
  chakra,
  Flex,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react"
import { EmailIcon } from "@chakra-ui/icons"
import { graphql, PageProps } from "gatsby"
import { StaticImage, getSrc, IGatsbyImageData } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import BlockHeader from "../components/blockHeader"
import { UniformBoxProps } from "../components/utils"
import Layout from "../components/layout"
import ContactForm from "../components/contactForm"
import SEO from "../components/CoreUI/SEO"

interface TimberFramePageProps extends PageProps {
  data: {
    ogImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  }
}

// section element to provide consistent widths/paddings
function UniformBox(props: UniformBoxProps): JSX.Element {
  return (
    <Box
      as="section"
      maxW={[`95vw`, `90vw`, `750px`, `1000px`]}
      mx="auto"
      my={[10, null, 12, 16]}
      overflowY="auto"
      w={{ sm: `650px`, md: `100%` }}
      {...props}
    />
  )
}

// basic strike-through element
const Strike = ({ children }) => {
  return (
    <span
      style={{ textDecoration: `line-through`, textDecorationColor: `red` }}
    >
      {children}
    </span>
  )
}

const TimberFrameHomes: React.FC<PageProps> = ({
  data,
  location,
}: TimberFramePageProps) => {
  gsap.registerPlugin(ScrollToPlugin)

  const contactRef = React.useRef<HTMLDivElement>()
  const plansRef = React.useRef<HTMLDivElement>()

  const ogImageUrl = getSrc(data.ogImage.childImageSharp.gatsbyImageData)

  const scrollToSection = (
    section: React.MutableRefObject<HTMLDivElement>,
    offsetY = 0
  ): void => {
    if (typeof window === `undefined`) return
    const scrollTo = { offsetY, y: section.current }
    gsap.to(window, { duration: 0.5, scrollTo })
  }

  const handlePlansButton = () => {
    scrollToSection(plansRef, 80)
  }

  const handleConsultButton = () => {
    scrollToSection(contactRef)
  }

  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Build a custom timber frame home in Jackson County, North Carolina.",
          image: `${location.origin}${ogImageUrl}`,
          imageAlt: `The interior of a custom timber frame home.`,
        }}
        title="Timber Frame Home Builder in Western NC | Sundog Homes"
        url={location.href}
      />
      {/* hero */}
      <UniformBox>
        <Flex
          align="center"
          justify="center"
          gap={8}
          direction={{ base: `column`, lg: `row` }}
        >
          <Flex flexDirection="column" align={{ base: `center`, sm: `start` }}>
            <BlockHeader bg="gray.700" width="fit-content">
              Timber Frame Homes
            </BlockHeader>
            <Text
              fontSize={[`4xl`, `5xl`]}
              fontFamily="heading"
              fontWeight="extrabold"
              lineHeight={1}
              pt={8}
              textAlign={{ base: `center`, sm: `left` }}
            >
              Art in engineering
            </Text>
            <Text color="gray.600" fontSize="lg" fontWeight="medium" pt={4}>
              Showcase the beauty of your custom home's structure with a
              Sundog-built timber frame home.
            </Text>
            <Flex direction={[`column`, `row`]} gap={4} mt={8} w="100%">
              <Button
                colorScheme="red"
                onClick={handlePlansButton}
                size="lg"
                variant="solid"
                w={{ base: `100%`, sm: `auto` }}
              >
                View Plans
              </Button>
              <Button
                bg="gray.50"
                leftIcon={<Icon as={EmailIcon} />}
                onClick={handleConsultButton}
                shadow="base"
                size="lg"
                variant="solid"
                w={{ base: `100%`, sm: `auto` }}
              >
                Schedule a Consult
              </Button>
            </Flex>
          </Flex>
          <Box maxH={{ base: `50vh`, md: `50vh`, lg: `auto` }} overflowY="clip">
            <StaticImage
              alt="The living room of a timber frame home, which showcases the building's structure."
              className="squared"
              height={1000}
              src="../images/timber-frame-1.jpg"
              style={{
                borderRadius: `var(--chakra-radii-base)`,
                boxShadow: `var(--chakra-shadows-md)`,
                minHeight: 350,
              }}
              transformOptions={{
                fit: `cover`,
              }}
              width={1000}
            />
          </Box>
        </Flex>
      </UniformBox>

      {/* partnership logos */}
      <Box bg="gray.700" gap={8} py={8}>
        <UniformBox my={0}>
          <Text color="gray.300" fontSize="base" textAlign="center">
            In Partnership with <strong>Woodhouse®</strong>
          </Text>
          <Flex align="center" justify="center" gap={16} mt={4}>
            <StaticImage
              alt="Woodhouse's logo"
              layout="fixed"
              src="../images/brands/woodhouse-logo-white.svg"
              imgStyle={{ filter: `brightness(10)` }}
              style={{ opacity: 1 }}
              width={150}
            />
            <StaticImage
              alt="Sundog Homes' logo"
              layout="fixed"
              src="../images/logo/white-flat.svg"
              imgStyle={{ filter: `brightness(10)` }}
              style={{ opacity: 1 }}
              width={150}
            />
          </Flex>
        </UniformBox>
      </Box>

      {/* copy section 1 */}
      <Box>
        <UniformBox>
          <Flex
            align="center"
            direction={{ base: `column`, lg: `row` }}
            gap={[8, null, null, 12]}
            justify="space-evenly"
          >
            <Box
              borderRadius="lg"
              maxH={{ base: `35vh`, lg: `auto` }}
              overflow="clip"
            >
              <StaticImage
                alt="Traditional column joinery in a timber frame home."
                className="squared"
                height={600}
                src="../images/timber-frame-detail-1-portrait.jpg"
                style={{
                  borderRadius: `var(--chakra-radii-base)`,
                  boxShadow: `var(--chakra-shadows-md)`,
                }}
                width={600}
              />
            </Box>
            <Box w={{ base: `100%`, lg: `50%` }}>
              <Heading as="h2" color="gray.800" fontWeight="bold">
                "They just <Strike>don't</Strike> make 'em like they used to."
              </Heading>
              <Text color="gray.600" fontSize="lg" pt={4}>
                Partnering with Woodhouse® – masters of timber frame design and
                construction since 1979 – we build full and hybrid timber frame
                homes that exemplify the timeless elegance and beauty of
                traditional building techniques.
              </Text>
              <Text color="gray.600" fontSize="lg" pt={4}>
                But it's not just about structure: timber frame architecture
                results in uniquely open and airy spaces that, along with the
                natural warmth of exposed wood, perfectly complement the big
                skies, old-growth forests, and hazy, layered ridges that define
                the mountains of Western NC.
              </Text>
            </Box>
          </Flex>
        </UniformBox>
      </Box>

      {/* full-width image 1 */}
      <UniformBox
        h={{ base: `auto`, lg: 450 }}
        maxH={{ base: `auto`, lg: 450 }}
        overflow="clip"
        borderRadius="lg"
      >
        <Box h="100%" bottom={{ base: `auto`, lg: 100 }} position="relative">
          <StaticImage
            alt="The kitchen of a timber frame home showcasing exposed beams, crossmembers, and columns."
            height={600}
            src="../images/timber-frame-interior-5-landscape.jpg"
            width={1000}
          />
        </Box>
      </UniformBox>

      {/* floor plans */}
      <Box bg="yellow.900" py={[10, null, 12, 16]} ref={plansRef}>
        <UniformBox mx="auto">
          <Heading
            as="h2"
            color="white"
            textAlign={{ base: `center`, md: `left` }}
            style={{ textWrap: `balance` }}
          >
            Browse Timber Frame Floor Plans
          </Heading>
          <Box my={8}>
            <StaticImage
              alt="A stylized grid of elevations for various Woodhouse plans"
              src="../images/woodhouse-plans.png"
              width={1000}
            />
          </Box>
          <Text
            fontSize="xl"
            color="yellow.50"
            maxW="65ch"
            mt={4}
            textAlign={{ base: `center`, md: `left` }}
            w="fit-content"
          >
            Woodhouse's extensive collection of 100+ expertly designed floor
            plans can be built as-is or customized to suit your needs and
            preferences.
          </Text>
          <Button
            as={Link}
            colorScheme="red"
            href="https://timberframe1.com/advanced-floor-plans-search/"
            mt={8}
            shadow="base"
            size="lg"
            variant="solid"
          >
            Browse Plans
          </Button>
        </UniformBox>
      </Box>

      {/* cta */}
      <Box bg="gray.50" py={[10, null, 12, 16]} ref={contactRef}>
        <UniformBox
          bg="white"
          my={0}
          p={{ base: 8, lg: 16 }}
          rounded="xl"
          shadow="xl"
        >
          <Stack align="center" spacing={4}>
            <Text
              fontFamily="heading"
              fontSize="4xl"
              fontWeight="extrabold"
              letterSpacing="tight"
              lineHeight="shorter"
              textAlign="center"
              style={{ textWrap: `balance` }}
            >
              <chakra.span color="red.600">Sundog</chakra.span> &{" "}
              <chakra.span color="#ad7314">Woodhouse</chakra.span> can deliver
              the custom timber frame home of your dreams
            </Text>
            <Text
              color="gray.700"
              fontSize="xl"
              fontWeight="medium"
              mb={6}
              maxW="65ch"
              mx="auto"
              textAlign="center"
            >
              Schedule a consult to learn more about our process, our expertise,
              and how we can bring your vision to life.
            </Text>
            <Stack
              align="center"
              direction={{ base: `column-reverse`, md: `row` }}
              justify="space-between"
              pt={4}
              spacing={{ base: 8, lg: 16 }}
              maxW={{ base: `55ch`, md: `100%` }}
              w="100%"
            >
              <Box flex="2" h="auto" pb={{ base: 12, lg: 0 }} w="100%">
                <Box w={{ base: `100%`, md: 325 }}>
                  <ContactForm
                    context={[]}
                    eventLabel="Schedule Consult (Get Started)"
                  />
                </Box>
              </Box>
              <Box
                bg="gray.50"
                display={{ base: `none`, md: `block ` }}
                rounded="lg"
                textAlign="center"
              >
                <StaticImage
                  alt="A timber frame home"
                  height={550}
                  src="../images/timber-frame-interior-6-portrait.jpg"
                  width={500}
                />
              </Box>
            </Stack>
          </Stack>
        </UniformBox>
      </Box>
    </Layout>
  )
}

export default TimberFrameHomes

export const query = graphql`
  {
    ogImage: file(name: { eq: "timber-frame-1" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          height: 630
          transformOptions: { fit: COVER }
          jpgOptions: { progressive: true, quality: 50 }
        )
      }
    }
  }
`
